<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0" v-if="!loading">
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            sm="6"
            class="d-flex align-items-center justify-content-center"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="52" variant="light-primary">
                  <feather-icon size="24" icon="UsersIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalUser }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Tổng số người dùng
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            md="6"
            sm="6"
            class="d-flex align-items-center justify-content-center"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="52" variant="light-primary">
                  <feather-icon size="24" icon="ShoppingCartIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalOrder }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Tổng số đơn hàng
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { StatsSystemEnum } from "@/@core/utils/enums/stats-system.enum";
import useToastHandler from "@/services/toastHandler";
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPagination,
  BRow,
  BTable,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
    BButton,
    BMediaAside,
    BMedia,
    BAvatar,
    BMediaBody,
    BCardText,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      maxPlus: 5,
      maxOrder: 0,
      maxUser: 0,
      totalUser: 0,
      totalOrder: 0,
      totalKeys: ["totalUser", "totalOrder"],
      totalMaxKeys: ["maxUser", "maxOrder"],
      intervals: [],
    };
  },
  computed: {
    ...mapState({
      loading: (s) => {
        return s.apps.dashboard.loading;
      },
    }),
  },
  async created() {
    await this.getDashboardReq();
    this.totalKeys.forEach((key, index) => {
      if (this[this.totalMaxKeys[index]] < this.maxPlus) {
        this[key] = this[this.totalMaxKeys[index]];
        return;
      }

      this.intervals[index] = setInterval(
        () => this.updateCounter(key, index),
        100
      );
    });
  },
  methods: {
    ...mapActions({
      getDashboard: "dashboard/getDashboard",
    }),

    async getDashboardReq() {
      const statsSystem = await this.getDashboard();
      if (!(statsSystem?.length > 0)) return;

      statsSystem.forEach((s) => {
        if (s?.statsSystemType === StatsSystemEnum.TotalOrder) {
          this.maxOrder = s?.total ? Number(s.total) : 0;
        }
        if (s?.statsSystemType === StatsSystemEnum.TotalUser) {
          this.maxUser = s?.total ? Number(s.total) : 0;
        }
      });
    },

    updateCounter(key, index) {
      const plus = Math.floor(this[this.totalMaxKeys[index]] / this.maxPlus);
      this[key] += plus;
      if (this[key] > this[this.totalMaxKeys[index]]) {
        this[key] = this[this.totalMaxKeys[index]];
        clearInterval(this.intervals[index]);
      }
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler();

    return {
      // Extra Filters
      showToastError,
      showToastSuccess,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
